import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }
  private columnFiltredSource = new Subject<any>();

  columnFiltred$ = this.columnFiltredSource.asObservable();

  columnFiltred(event) {

    if (event.messageDate && event.messageDate !== '') {
      event.messageDate = moment(event.messageDate, 'DD\MM\YYYY').format('YYYY-MM-DD');
    }
    if (event.createdDate && event.createdDate !== '') {
      event.createdDate = moment(event.createdDate, 'DD\MM\YYYY').format('YYYY-MM-DD');
    }
    if (event.reschedulingDate && event.reschedulingDate !== '') {
      event.reschedulingDate = moment(event.reschedulingDate, 'DD\MM\YYYY').format('YYYY-MM-DD');
    }
    this.columnFiltredSource.next(event);
  }
}
