import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {Response} from '../../../@core/models/response.model';
import {Profil} from '../models/profil.model';
import {User} from '../../../@core/models/user.model';
import {AuthorityModel} from '../models/authority.model';
import {CodificationModel} from '../models/codification.model';
import {AttachmentModel} from '../models/attachment.model';
import {AuthHelper} from '../../../@core/services/security/auth.helper';
import { ChargeModel } from '../models/charge.model';


let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');
@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private urlApi: string;
  authorities: Array<AuthorityModel> = [];
  user: User;
  authority: AuthorityModel;

  constructor(private httpClient: HttpClient) {
    this.urlApi = environment.apiConfig.apiUrl;
    // this.getAllAutorities();
  }

  getAllAutorities() {
    if (localStorage.getItem(AuthHelper.USER_ID)) {
      this.user = JSON.parse(localStorage.getItem(AuthHelper.USER_ID));
      if (localStorage.getItem(AuthHelper.USER_ID)) {
        this.user = JSON.parse(localStorage.getItem(AuthHelper.USER_ID));
        this.user.profileDtos.forEach(profile => {
        this.getAuthoritiesByProfileId(profile.id).subscribe(response => {
          this.authorities = response.data;
        });
       });
      }
    }
  }

  dashboard(): Observable<Response<Array<any>>> {
    // return this.httpClient.get<Response<Array<any>>>(`${this.urlApi}/admin/dashboard`);
    return null;
  }

  getUserbyID(idUser: number): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/users/moderators//${idUser}`);
  }

  // Settings General

  getConfiguration(): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/settings/configuration`);
  }

  saveConfiguration(configuration: any): Observable<Response<number>> {
    return this.httpClient.post<Response<number>>(`${this.urlApi}/settings/configuration/save`, configuration, {headers});
  }

  // Profils
  getListProfils(): Observable<Response<Array<Profil>>> {
    return this.httpClient.get<Response<Array<Profil>>>(`${this.urlApi}/get/profiles`);
  }

  getPageableListProfils(page: number, pageSize: number, keyword?: string, filter?: any, sort?: any): Observable<Response<any>> {
    if (keyword) {
      return this.httpClient.put<Response<any>>(`${this.urlApi}/get/profiles/paged-list?page=${page}&size=${pageSize}&keyword=${keyword}`, {
        filter,
        sort
      });
    } else {
      return this.httpClient.put<Response<any>>(`${this.urlApi}/get/profiles/paged-list?page=${page}&size=${pageSize}`, {filter, sort});
    }
  }

  getPageableListProfilsSimple(pageNo?: number, countNo?: number, keyword?: string, filter?: any, sort?: any): Observable<Response<Array<Profil>>> {
    return this.httpClient.get<Response<Array<Profil>>>(`${this.urlApi}/get/profiles/paged-list?page=${pageNo}&size=${countNo}`);
  }

  dropProfil(idProfil: number) {
    return this.httpClient.delete(`${this.urlApi}/edit/profiles/${idProfil}`, {headers});
  }

  saveProfile(profile: Profil) {
    return this.httpClient.post(`${this.urlApi}/add/profiles`, profile, {headers});
  }

  updateProfile(id: number, profile: Profil) {
    return this.httpClient.put(`${this.urlApi}/edit/profiles/${id}`, profile, {headers});
  }
  updateCharge(id: number, charge: ChargeModel) {
    return this.httpClient.post(`${this.urlApi}/edit/charge/${id}`, charge, {headers});
  }

  getProfile(idProfile: string): Observable<Response<Profil>> {
    return this.httpClient.get<Response<Profil>>(`${this.urlApi}/get/profiles/${idProfile}`, {headers});
  }
  getCharge(idCharge: string): Observable<Response<ChargeModel>> {
    return this.httpClient.get<Response<ChargeModel>>(`${this.urlApi}/get/charge/${idCharge}`, {headers});
  }
  getAllAuthoritiesByCategory(): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/get/profiles/authorities`);
  }
  // Codification
  saveCodification(codif: CodificationModel) {
    return this.httpClient.post(`${this.urlApi}/add/codif`, codif, {headers});
  }
  saveCharge(charge: ChargeModel) {
    return this.httpClient.post(`${this.urlApi}/add/charge`, charge, {headers});
  }
  updateCodification(idCodif: number, codif: CodificationModel) {
    return this.httpClient.put(`${this.urlApi}/edit/codif/${idCodif}`, codif, {headers});
  }

  enableCodification(idCodification: number): Observable<Response<CodificationModel>> {
    return this.httpClient.put<Response<CodificationModel>>(`${this.urlApi}/edit/codif/${idCodification}/enable`, null, {headers});
  }

  disableCodification(idCodification: number): Observable<Response<CodificationModel>> {
    return this.httpClient.put<Response<CodificationModel>>(`${this.urlApi}/edit/codif/${idCodification}/disable`, null, {headers});
  }

  dropCodification(idCodif: number) {
    return this.httpClient.delete(`${this.urlApi}/edit/codif/${idCodif}`, {headers});
  }
  deleteCharge(idCharge: number) {
    return this.httpClient.post(`${this.urlApi}/edit/delete/charge/${idCharge}`, {headers});
  }
  getCodification(idCodif: string): Observable<Response<CodificationModel>> {
    return this.httpClient.get<Response<CodificationModel>>(`${this.urlApi}/get/codif/${idCodif}`, {headers});
  }

  getCodificationByCode(code: string): Observable<Response<CodificationModel>> {
    return this.httpClient.get<Response<CodificationModel>>(`${this.urlApi}/get/codif/bycode?code=${code}`, {headers});
  }

  getListCodifications(): Observable<Response<Array<CodificationModel>>> {
    return this.httpClient.get<Response<Array<CodificationModel>>>(`${this.urlApi}/get/codif`);
  }

  getPageableListCodifications(status: string, pageNo?: number, countNo?: number, keyword?: string, filter?: any, property?: string, direction?: string): Observable<Response<Array<CodificationModel>>> {
    return this.httpClient.get<Response<Array<CodificationModel>>>(`${this.urlApi}/get/codif/paged-list?status=${status}&page=${pageNo}&size=${countNo}&property=${property}&direction=${direction}`);
  }

  getListClasses(): Observable<Response<Array<string>>> {
    return this.httpClient.get<Response<Array<string>>>(`${this.urlApi}/get/codif/classes`);
  }

  getListCodificationsByClassPaged(status?: string , classe?: string, pageNo?: number, countNo?: number): Observable<Response<Array<CodificationModel>>> {
    return this.httpClient.get<Response<Array<CodificationModel>>>(`${this.urlApi}/get/codif/byclass/paged?status=${status}&class=${classe}&page=${pageNo}&size=${countNo}`);
  }
  getListCharges(): Observable<Response<Array<ChargeModel>>> {
    return this.httpClient.get<Response<Array<ChargeModel>>>(`${this.urlApi}/get/charges`);
  }
  getListCodificationsByClass(classe?: string): Observable<Response<Array<CodificationModel>>> {
    return this.httpClient.get<Response<Array<CodificationModel>>>(`${this.urlApi}/get/codif/byclass?class=${classe}`);
  }
  // Attachment
  saveAttachment(attachment: AttachmentModel) {
    return this.httpClient.post(`${this.urlApi}/add/attachments`, attachment, {headers});
  }

  updateAttachment(idAttachment: number, attachment: AttachmentModel) {
    return this.httpClient.put(`${this.urlApi}/edit/attachments/${idAttachment}`, attachment, {headers});
  }

  enableAttachment(idAttachment: number): Observable<Response<AttachmentModel>> {
    return this.httpClient.put<Response<AttachmentModel>>(`${this.urlApi}/edit/attachments/${idAttachment}/enable`, null, {headers});
  }

  disableAttachment(idAttachment: number): Observable<Response<AttachmentModel>> {
    return this.httpClient.put<Response<AttachmentModel>>(`${this.urlApi}/edit/attachments/${idAttachment}/disable`, null, {headers});
  }

  dropAttachment(idAttachment: number) {
    return this.httpClient.delete(`${this.urlApi}/edit/attachments/${idAttachment}`, {headers});
  }

  getAttachment(idAttachment: string): Observable<Response<AttachmentModel>> {
    return this.httpClient.get<Response<AttachmentModel>>(`${this.urlApi}/get/attachments/${idAttachment}`, {headers});
  }

  getListAttachments(): Observable<Response<Array<AttachmentModel>>> {
    return this.httpClient.get<Response<Array<AttachmentModel>>>(`${this.urlApi}/get/attachments`);
  }

  getPageableListAttachments(status: string, pageNo?: number, countNo?: number, keyword?: string, filter?: any, property?: string, direction?: string): Observable<Response<Array<AttachmentModel>>> {
    return this.httpClient.get<Response<Array<AttachmentModel>>>(`${this.urlApi}/get/attachments/paged-list?status=${status}&page=${pageNo}&size=${countNo}&property=${property}&direction=${direction}`);
  }

  getListAttachmentsByFonAndTypePaged(status: string, codeFond?: string, type?: string, pageNo?: number, countNo?: number): Observable<Response<Array<AttachmentModel>>> {
    return this.httpClient.get<Response<Array<AttachmentModel>>>(`${this.urlApi}/get/attachments/fond/paged?status=${status}&codeFond=${codeFond}&type=${type}&page=${pageNo}&size=${countNo}`);
  }

  getFileById(id: number): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/get/attachments/open/${id}`, {headers});
  }
  // Users
  getListUsers(pageNo?: number, countNo?: number, keyword?: string, filter?: any, property?: string, direction?: string): Observable<Response<Array<User>>> {
    return this.httpClient.get<Response<Array<User>>>(`${this.urlApi}/get/users/paged-list?page=${pageNo}&size=${countNo}&property=${property}&direction=${direction}`);
  }

  dropUser(idUser: number): Observable<Response<User>> {
    return this.httpClient.delete<Response<User>>(`${this.urlApi}/edit/users/${idUser}`, {headers});
  }

  createUser(user: User): Observable<Response<User>> {
    return this.httpClient.post<Response<User>>(`${this.urlApi}/add/users`, user, {headers});
  }

  enableUser(idUser: number): Observable<Response<User>> {
    return this.httpClient.put<Response<User>>(`${this.urlApi}/edit/users/${idUser}/enable`, null, {headers});
  }

  disableUser(idUser: number): Observable<Response<User>> {
    return this.httpClient.put<Response<User>>(`${this.urlApi}/edit/users/${idUser}/disable`, null, {headers});
  }

  editUser(idUser: number, user: User): Observable<Response<User>> {
    return this.httpClient.put<Response<User>>(`${this.urlApi}/edit/users/${idUser}`, user, {headers});
  }

  editPasswordUser(oldPassword: string, user: User): Observable<Response<User>> {
    return this.httpClient.put<Response<User>>(`${this.urlApi}/get/users/reset-password?oldpasswd=${oldPassword}`, user, {headers});
  }

  getUser(idUser: string): Observable<Response<User>> {
    return this.httpClient.get<Response<User>>(`${this.urlApi}/get/users/${idUser}`, {headers});
  }

  getListAuthorities(): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/get/profiles/authorities`, {headers});
  }

  getAuthorities(id: number): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/get/profiles/${id}/authorities`, {headers});
  }

  getAuthoritieById(id: number): Observable<Response<AuthorityModel>> {
    return this.httpClient.get<Response<AuthorityModel>>(`${this.urlApi}/get/profiles/authorities/${id}`, {headers});
  }

  getAuthoritiesByProfileId(id: number): Observable<Response<Array<AuthorityModel>>> {
    return this.httpClient.get<Response<Array<AuthorityModel>>>(`${this.urlApi}/get/profiles/authorities/profile/${id}`, {headers});
  }

  getAccountType(id: number): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/get/profiles/${id}/account-types`, {headers});
  }

}
