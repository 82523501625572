import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import 'rxjs/add/operator/do';
import {HttpStatusService} from '../services/loader/http-status.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable()
export class HTTPListenerInterceptor implements HttpInterceptor {

  constructor(
    private httpStatus: HttpStatusService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map(event => {
          return event;
        }),
        catchError(error => {
          return throwError(error);
        }),
        finalize(() => {
          // return true to mark the NgProgress as done check the app.component.ts
          this.httpStatus.setHttpStatus(true);
        })
      );
  }
}
