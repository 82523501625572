import {AdminService} from './../../../../main/core/services/admin.service';
import {Component, Input, OnInit} from '@angular/core';
import {Statut} from '../../../models/user.model';
import {environment} from '../../../../../environments/environment';
import {ColumnFiltersModel} from '../../../models/columnFilteredEvent.model';

import * as moment from 'moment';
import {FilterService} from '../../../services/filter.service';

@Component({
  // tslint:disable-next-line
  selector: '[filterable-column]',
  templateUrl: './filter-column.component.html',
  styleUrls: ['./filter-column.component.scss']
})
export class FilterColumnComponent implements OnInit {
  today: Date = new Date();
  filter = new ColumnFiltersModel();
  statusTypes = Statut;
  listProfils = [];

  // tslint:disable-next-line
  @Input('filterable-column')
  columnName: string;

  // tslint:disable-next-line
  @Input('filter-type')
  filtertype: string;

  // tslint:disable-next-line
  @Input('filter-disabled')
  filterDisabled: string;

  // tslint:disable-next-line
  @Input('filter-status-for')
  filterStatusFor = 'default';
  keyword: any;

  constructor(private filterService: FilterService, private adminService: AdminService) {
  }

  statusList(): Array<string> {
    const statusList = Object.keys(this.statusTypes);
    // TODO: some filters
    return statusList;
  }

  getProfiles(): void {
    this.adminService.getListProfils().subscribe((response) => {
      if (response && response.data) {
        this.listProfils = response.data;
      }
    }, (err) => {
    });
  }

  dateChanged(input, filterType): void {
    let tempDate = null;
    if (filterType === 'createdDate') {
      tempDate = this.filter.createdDate;
    }
    if (tempDate && tempDate !== '') {
      this.filter.createdDate = moment(new Date(tempDate)).format(environment.defaultDateFormatNoTime);
      input.value = moment(new Date(tempDate)).format(environment.defaultDateFormatNoTime);
      this.search();
      setTimeout(() => {
        input.value = moment(new Date(tempDate)).format(environment.defaultDateFormatNoTime);
      }, 100);
    }
  }

  search() {
    this.iniValues();
    this.filterService.columnFiltred(this.filter);
  }

  ngOnInit() {
    this.iniValues();
    if (this.filtertype === 'profile') {
      this.getProfiles();
    }
  }

  iniValues() {
    const filters = {
      lastName: document.querySelector('input#lastName'),
      firstName: document.querySelector('input#firstName'),
      username: document.querySelector('input#username'),
      email: document.querySelector('input#email'),
      profile: document.querySelector('select#profile option:checked'),
      enabled: document.querySelector('select#enabled option:checked'),
      description: document.querySelector('input#description'),
      name: document.querySelector('input#name'),
      status: document.querySelector('select#status option:checked'),
      createdDate: document.querySelector('input#createdDate'),
      code: document.querySelector('input#code'),
      type_titrisation: document.querySelector('input#type_titrisation'),
      code_type_tit: document.querySelector('input#code_type_tit'),
      code_cedant: document.querySelector('input#code_cedant'),
      id: document.querySelector('input#id'),
      raisonSociale: document.querySelector('input#raisonSociale'),
      c1: document.querySelector('input#c1'),
      c2: document.querySelector('input#c2'),
      c3: document.querySelector('input#c3'),
      c4: document.querySelector('input#c4'),
      c5: document.querySelector('input#c5'),
      c6: document.querySelector('input#c6'),
      c7: document.querySelector('input#c7'),
      c8: document.querySelector('input#c8'),
      c9: document.querySelector('input#c9'),
      c10: document.querySelector('input#c10'),
    };

    for (const key in filters) {
      if (this.filter.hasOwnProperty(key)) {
        if (filters[key] && filters[key].value !== '') {
          this.filter[key] = filters[key].value;
        } else if (key === 'status' || key === 'enabled' || key === 'profile' || key === 'parent') {
          // @ts-ignore
          this.filter[key] = null;
        } else {
          this.filter[key] = '';
        }
      }
    }

  }

}
