// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {LoggerTypes} from '../app/@core/factories/logger.type';
import {ErrorHandlerTypes} from '../app/@core/factories/error-handler.type';
import {AuthScheme} from '../app/@core/models/auth-scheme.enum';
import {AuthTypes} from '../app/@core/factories/auth.type';


 //const server = 'http://172.20.55.250:8282/api';
 //const server = 'http://172.20.55.250:8080/api';
const server = 'http://report.milaffat.ma:8282/api';

export const environment = {
  appName: 'sofasys',
  appLogo: './assets/images/logoSofac.png',
  defaultAvatar: './assets/images/profil.png',
  // tslint:disable-next-line:max-line-length
  defaultApplicationLogo: './assets/images/logo.png',
  production: false,
  envName: 'dev',
  buildVersion: '0.0.1-SNAPSHOT',
  buildTimestamp: new Date().toISOString(),
  defaultLanguage: 'fr',
  defaultDateFormat: 'DD-MM-YYYY HH:mm',
  defaultDateFormatNoTime: 'DD-MM-YYYY',
  // tslint:disable-next-line:max-line-length
  emailPattern: '^([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$',
  mobilenumPattern: '^(\\+212|0)([ \\-_/]*)(\\d[ \\-_/]*){9}$',
  // tslint:disable-next-line:max-line-length
  emailRegExp: /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/,
  authToken: localStorage.getItem('authToken'),
  apiConfig: {
    apiEnv: 'dev',
    timeExpired: 1200,
    credentials: {
      /*      clientId: 'admin',
            clientSecret: 'admin'*/
      clientId: '',
      clientSecret: ''
    },
    apiUrls: [
      {id: 'About_SERVICE_URL', url: server + '/', requireAuthBefore: false},
      {id: 'OAUTH_SERVICE_URL', url: server + '/auth', requireAuthBefore: false},
      {id: 'RESET_PASSWORD_SERVICE_URL', url: server + '/auth/reset-password', requireAuthBefore: false},
      {id: 'RECOVER_PASSWORD_SERVICE_URL', url: server + '/auth/recover-password', requireAuthBefore: false},
    ],
    apiUrl: server,
    apiUploads: server + '/attachments/upload',
    authService: AuthTypes.OAUTH,
    authScheme: AuthScheme.BEARER,
    errorHandler: ErrorHandlerTypes.SIMPLE,
    loggerService: LoggerTypes.CONSOLE
  },
  fileSettings : {
    taille : 20000000,
    tailleByMega : 20,
    format : ['PDF'],
    mimeType : 'application/pdf'
  }
};

