import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class CommonTranslateService {

  constructor(private translate: TranslateService) {
  }

  getArray(key?: string[]): any {
    return new Promise((resolve, reject) => {
      this.translate.get(key).subscribe(success => {
        resolve(success);
      });
    });
  }

  get(key?: string): any {
    return new Promise((resolve, reject) => {
      this.translate.get(key).subscribe(success => {
        resolve(success);
        console.log(success);
      });
    });
  }

}
