import {User} from "../../@core/models/user.model";

export class UserDetails extends User {

  constructor(id: number,
              username: string,
              password: string,
              firstname: string,
              lastname: string,
              email: string) {
    super(id,
      username,
      password,
      firstname,
      lastname,
      email
    );
  }
}

export enum UserProfile {
  ADMINISTRATION = 'admin'
}

export enum UserAccountType {
  ADMINISTRATION = 'ADMINISTRATION'
}

export enum Statut {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export const Authorities = {
  ADMINISTRATION: [
    'CreateUsers',
    'DeleteUers'
  ],
  OTHERS: []
};

export interface PermissionSuperAdmin {
  PermissionAdmin: PermissionAdmin;
  AdminNavigation: AdminNavigation;
}

export interface PermissionAdmin {
  ManageProfiles: 'ManageProfiles';
  ManageUsers: 'ManageUsers';
  Settings: 'Settings';
  AdminNavigation: AdminNavigation;
}

export enum AdminNavigation {
  AccessDashboardPage = 'AccessDashboardPage'
}
