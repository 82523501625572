<nb-layout>
  <nb-layout-column><div class="row">
    <div class="col-md-4 offset-md-4">
      <nb-card>
        <nb-card-header><div class="col-md-6"> <h2>Login</h2> </div></nb-card-header>
        <nb-card-body>
          <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="form-horizontal">
            <div class="form-group row">
              <div class="col-sm-12">
                <input formControlName="username" type="email" nbInput fullWidth id="inputEmail3" placeholder="Email">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <input  formControlName="password" type="password" nbInput fullWidth id="inputPassword3" placeholder="Password">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-9">
                <div class="checkbox">
                  <nb-checkbox>Remember me</nb-checkbox>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-9">
                <button type="submit" nbButton status="primary" [disabled]="!loginForm.valid">Log in</button>
              </div>
             
            </div>
          </form>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</nb-layout-column>
</nb-layout>
