import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { DefaultEditor, DefaultFilter, ViewCell } from 'ng2-smart-table';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'smart-table-datepicker',
  templateUrl: './smart-table-datepicker.component.html',
  styleUrls: ['./smart-table-datepicker.component.css']
})
export class SmartTableDatepickerComponent extends DefaultEditor implements OnInit ,OnChanges{
    inputControl = new FormControl();
    constructor() {
        super();
      }
    ngOnInit() {
        this.inputControl.setValue(this.cell.newValue);
        this.inputControl.valueChanges.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
        ).subscribe((value: string) => {
          this.cell.newValue = value;
        });

    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
          this.inputControl.setValue(this.inputControl.value);
        }
      }
}

@Component({
  template: `{{ formatDate(value) }} `,
})
export class SmartTableDatepickerRenderComponent implements ViewCell, OnInit {
  @Input() value: any;
  @Input() rowData: any;

  constructor() { }

  ngOnInit() { }

  formatDate(date: any) {
    if (typeof date === 'string') {
        return date;
    }
    if (date instanceof Date) {
        return date.toLocaleDateString();
    }else{
       let mydate = new Date(date.start).toLocaleDateString();
    return mydate;
    }
  }
}