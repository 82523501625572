import {Profil} from '../../main/core/models/profil.model';

export class User {
  constructor(
    public id?: number,
    public username?: string,
    public password?: string,
    public firstname?: string,
    public lastname?: string,
    public email?: string,
    public mobile?: string,
    public enabled?: boolean,
    public authType?: string,
    public profileDtos?: Array<Profil>,
    public profilesId?: Array<number>,
    public avatar?: string,
  ) {
  }
}
