import {Injectable, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {AuthHelper} from '../services/security/auth.helper';
import {User} from "../../main/core/models/user";
import {AuthorityModel} from "../../main/core/models/authority.model";

@Injectable()
export class AdminGuard implements CanActivate {
  user: User;
  authStr: Array<string> = [];
  constructor(private router: Router, private authHelper: AuthHelper) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authHelper.isUserLogged()) {
      this.user = JSON.parse(localStorage.getItem(AuthHelper.USER_ID));
      next.data.permissions.user = this.user;
    } else {
      return false;
    }
    if(this.user && this.user.profileDtos){
      this.user.profileDtos.forEach(profiles => {
        if(profiles.authorities){
          profiles.authorities.forEach(value => {
            if(this.authStr.indexOf(value.code) === -1){
            this.authStr.push(value.code)} 
          } );          
          }
         });
         if (this.authStr && this.authStr.indexOf(next.data.permissions.only) === -1) {
          // role not authorised so redirect to home page
         this.router.navigate([next.data.permissions.redirectTo]);
         return false;
         }
      }
    return true;
  }


}
