import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {
  currentPage: number;
  @Input() totalItems: number;
  @Input() numberOfItems: number;
  @Input() totalPages: number;
  // @Input() maxSize: number;
  maxSize = 6;
  @Input() disabled: boolean;
  @Input() align: boolean;
  @Input() pageBtnClass: string;
  // @Input() firstText: string;
  // @Input() lastText: string;
  firstText = 'first';
  lastText = 'last';
  @Input() itemsPerPage: number;
  @Output() numPages: EventEmitter<any> = new EventEmitter();
  @Output() onpageChanged: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.onpageChanged.emit(event.page);
  }

  ngOnInit() {
    
  }

}
