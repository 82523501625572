import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import * as _ from  'underscore';
import {
  NbToastrService,
} from '@nebular/theme';
import { throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private toastrService: NbToastrService
  ) {
  }
  showToast(message:string,status:string,) {
    this.toastrService.show(message, 'Login', { status: status });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    const authReq = req.clone({headers: req.headers.set('headerName', 'headerValue')});

// send the newly created request
    return next.handle(authReq).do((event: HttpEvent<any>) => {
      // stop foreground loading
     
    })
      .catch((response, caught) => {
        if (response && response.error) {
          console.log('error Occurred ------');
          if (response.error.data) {
            if (typeof response.error.data === 'string') {
              this.translate.get(response.error.data).subscribe(msg => {
                this.showToast(msg,"danger")
                console.log(this.translate.instant(msg), '', {timeOut: 1000});
              });
              if (response.error.data === 'PERMISSION_NOT_GRANTED') {
                setTimeout(() => {
                  this.router.navigate(['/dashbord']);
                }, 100);
              }
            } else if (typeof response.error.data === 'object') {
              _.each(response.error.data, (error: any) => {
                this.translate.get(error.field).subscribe(field => {
                  this.translate.get(error.errorCode).subscribe(msgError => {
                    this.showToast(msgError,"danger")
                  });
                });
              });
            }
            console.log(response.error.data);
            // is token expired redirect to login
          } else if (response.error.error === 'Unauthorized') {
            setTimeout(() => {
              this.router.navigate(['/auth/login']);
            }, 100);
          } else {
            if (response.status === 404 || response.status === 400 || response.status === 500) {
              if (typeof response.error === 'string') {
                this.translate.get(JSON.parse(response.error).message).subscribe(msg => {
                  this.showToast(msg,"warning")
                });

              } else {
                if (response.error.message) {
                  this.translate.get(response.error.message).subscribe(msg => {
                    this.showToast(msg,"warning")
                  });
                }
              }
            } else if (response.status === 0) { // Server down
              this.translate.get('SERVER_DOWN').subscribe(msgError => {
                this.showToast(msgError,"warning")
                console.log(msgError);
              });
              setTimeout(() => {/*
                this.router.navigate(['/maintenance']);*/
              }, 100);
            } else if (response.status === 403) { // response forbidin (authotity is missing)
              this.router.navigate(['/admin/unauthorized']);
            }
          }
        }
// intercept the error response and displace it to the console
        console.log(response);
// return the error to the method that called it
        return throwError(response);
      }) as any;
  }
}
