import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthHelper} from '../services/security/auth.helper';
import { throwError } from 'rxjs';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authHelper: AuthHelper) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isUserAuthenticated(request.url)) {
      return throwError({status: 401, statusText: 'UNAUTHORIZED'});
    } else {
      return next.handle(request);
    }
  }

  /**
   * Returns true If the url doesn't require previous authentication or
   * require it but the user is already logged in
   */
  private isUserAuthenticated(url) {
    return this.authHelper.isUserLogged() || !this.authHelper.needAuthBefore(url);
  }
}
