export class ColumnFiltersModel {
  firstName = '';
  lastName = '';
  username = '';
  email = '';
  profile: any = null;
  enabled: boolean = null;
  title = '';
  description = '';
  type = '';
  name = '';
  content = '';
  status: string = null;
  createdDate = '';
  parent: number = null;
  id: number = null;
  code = '';
  label = '';
  type_titrisation = '';
  code_type_tit = '';
  code_cedant = '';
  raisonSociale = '';
  familleTitrisation = '';
  typeActif = '';
  natureCession = '';
  c1 = '';
  c2 = '';
  c3 = '';
  c4 = '';
  c5 = '';
  c6 = '';
  c7 = '';
  c8 = '';
  c9 = '';
  c10 = '';
  extensible: boolean;
}
