import { Component } from '@angular/core';
import {OAuthService} from '../../@core/services/security/oauth.service';
import {NbToastrService} from '@nebular/theme';
import {TranslateService} from "@ngx-translate/core";
import { Router } from '@angular/router';
@Component({ 
    selector: 'mcm-logout',
    template: '<div></div>',
  })
export class LogoutComponent {
    constructor(
        private oauthService: OAuthService,
        private toastrService: NbToastrService,
        private translate: TranslateService,
        private router: Router){}
    ngOnInit() {
        this.logout();
    }
    public logout() {
    this.oauthService.logout();
    this.translate.get('logout.success').subscribe(msg => {
      this.toastrService.show(msg,"success");
    });
    this.router.navigate(['/auth/login']);
  }
  }
