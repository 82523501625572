import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PaginationComponent } from './components/tables/pagination/pagination.component';
import {FilterComponent} from './components/tables/filter/filter.component';
import { SortableColumnComponent } from './components/tables/sortable-column/sortable-column.component';
import { FilterColumnComponent } from './components/tables/filter-column/filter-column.component';
import { FilterTableDirective } from './directives/filter-table.directive';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import { SmartTableDatepickerComponent } from './components/smart-datepicker/smart-table-datepicker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NbDatepickerModule } from '@nebular/theme';
@NgModule({
  declarations: [
    PaginationComponent,
    FilterComponent,
    SortableColumnComponent,
    FilterColumnComponent,
    FilterTableDirective,
    SmartTableDatepickerComponent
  ],
  exports: [
    PaginationComponent,
    FilterComponent,
    SortableColumnComponent,
    FilterColumnComponent,
    FilterTableDirective,
    PaginationModule,
    SmartTableDatepickerComponent
  ],
  imports: [
    PaginationModule.forRoot(),
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DatePipe,
    NbDatepickerModule,
    
  ],
  providers: [
  ]
})
export class GlobalSharedModule { }
