import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from '../../@core/services/security/auth.service';
import {ValidationService} from '../../@core/services/validation/validation.service';
import {environment} from '../../../environments/environment';
import {
  NbToastrService,
} from '@nebular/theme';

@Component({ 
  selector: 'mcm-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public loginForm!: FormGroup;
  username: string = environment.apiConfig.credentials.clientId;
  password: string = environment.apiConfig.credentials.clientSecret;
    constructor(private router: Router,
       private fb: FormBuilder,
       private toastrService: NbToastrService,
       private translate: TranslateService,
       @Inject('AuthService') private authService: AuthService,
       validation: ValidationService) {    
      translate.setDefaultLang('en');
      translate.use('en');
      this.loginForm = this.fb.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
      });
    }
    onLogin() {
        this.doLogin();
    }
    doLogin() {
      const user = this.loginForm.get('username').value;
      const pass = this.loginForm.get('password').value;
      
      this.authService.login(user, pass)
        .subscribe(userData => {
          // navigate to returnUrl just if login successfully
          if (userData.accessToken) {
            this.showToast ("Bienvenue","success");
            setTimeout(() => {
              this.router.navigate(['/pages/dashboard']);
            }, 1000);
          } else {
            this.translate.get('login.error-no-token-sent').subscribe(msg => {
              this.showToast (msg,"danger");
            });
          }
        }, error => {
          console.log(error);  
        });
    }
    onclicklogo(){
    }
    showToast(message:string,status:string,) {
      this.toastrService.show(message, 'Connexion', { status: status });
    }
}