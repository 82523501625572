<!--<div class="row align-items-center">
  <div class="col-xs-6 col-6">
&lt;!&ndash;
    <strong *ngIf="totalPages">{{'Afficher' | translate}} {{numberOfItems + ' / ' + totalItems}} {{'entrées' | translate}}</strong>
&ndash;&gt;
  </div>
  <div class="col-xs-6 col-6 doNotPrint">
    <pagination [totalItems]="totalItems"
                [(ngModel)]="currentPage"
                [class]="pageBtnClass + ' justify-content-center pagination-sm '"
                [align]="align"
                [disabled]="disabled"
                [maxSize]="maxSize"
                [lastText]="lastText"
                [firstText]="firstText"
                [previousText]="'<<' | translate"
                [nextText]="'>>' | translate"
                [itemsPerPage]="itemsPerPage"
                [boundaryLinks] = true
                (pageChanged)="pageChanged($event)"></pagination>
  </div>
</div>-->
<pagination [totalItems]="totalItems"
            [(ngModel)]="currentPage"
            [class]="pageBtnClass + ' justify-content-center pagination-sm '"
            [align]="align"
            [disabled]="disabled"
            [maxSize]="maxSize"
            [lastText]="lastText"
            [firstText]="firstText"
            [previousText]="'<<' | translate"
            [nextText]="'>>' | translate"
            [itemsPerPage]="itemsPerPage"
            [boundaryLinks] = true
            (pageChanged)="pageChanged($event)"></pagination>
