import {Injectable} from '@angular/core';
import {UtilsService} from './utils.service';
import {TranslateService} from '@ngx-translate/core';
import {UserAccountType} from '../../shared/models/user.model';

@Injectable()
export class CommonService {

  constructor(private utilsService: UtilsService,
              private translate: TranslateService) {
  }


  public setSettings(text?,
                     enableSearchFilter?,
                     groupBy?,
                     badgeShowLimit?,
                     disabled?,
                     searchPlaceholderText?,
                     selectAllText?,
                     unSelectAllText?,
                     classes?,
                     limitSelection?) {
    return {
      text: text || this.translate.instant('Selectionner une valeur'),
      enableSearchFilter: enableSearchFilter || true,
      badgeShowLimit: badgeShowLimit,
      disabled: disabled || false,
      selectAllText: selectAllText || this.translate.instant('selectionner-tout'),
      unSelectAllText: unSelectAllText || this.translate.instant('tout-deselectionner'),
      classes: classes || 'myclass custom-class',
      searchPlaceholderText: searchPlaceholderText || this.translate.instant('rechercher'),
      groupBy: groupBy || groupBy,
      noDataLabel: this.translate.instant('pas-de-donnees-disponibles'),
      limitSelection: limitSelection
    };
  }


  public permission(authorities) {
    for (const accountType in UserAccountType) {
      if (authorities.category.title === accountType) {

      }
    }
/*    this.rolesService.addRole(authorities.category, authorities.authorities);
    this.permissionsService.loadPermissions(authorities.authorities);*/

/*    this.dataService.set('permissions', JSON.stringify(this.permissionsService.getPermissions()));
    this.dataService.set('roles', JSON.stringify(this.rolesService.getRoles()));*/
    // console.log('Roles: ', this.rolesService.getRoles());
    // console.log('Permissions: ', this.permissionsService.getPermissions());
  }

  public loadPermissions(): void {
/*    this.rolesService.flushRoles();
    this.permissionsService.flushPermissions();
    /!*let roles = this.dataService.get('roles');*!/
    let roles;
    if (roles) {
      roles = JSON.parse(roles);
      for (const key in roles) {
        if (roles.hasOwnProperty(key)) {
          this.rolesService.addRole(key, roles[key]);
          this.permissionsService.addPermission(roles[key].validationFunction);
        }
      }
    }*/

  }

  public hasPermission(permission) {
    /*return this.permissionsService.getPermission(permission);*/
  }
  public hasPermissionAuthority(array: Array<any>, authMenu: string) {
    for (let i = 0 ; i < array.length ; i++) {
      if (array[i].code === authMenu) {
        return  true;
      }
    }
    return false ;
  }
  public inCategories(array: Array<any>, catMenu: string) {
    for (let i = 0 ; i < array.length ; i++) {
      if (array[i].title === catMenu) {
        return  true;
      }
    }
    return false ;
  }
}
