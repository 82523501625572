<div class="form-group">
  <div class="input-group mb-3" id="filterControl">
    <div class="input-group-append">
      <label class="input-group-text" for="inputGroupSelect02"><i class="fa fa-list-ol" aria-hidden="true"></i></label>
    </div>
    <select class="custom-select border-left-0 rounded-0" id="inputGroupSelect02"
            [(ngModel)]="selectedItem"
            (change)="filterChanged($event)">
      <option *ngFor="let c of filters"
              value="{{c.value}}">{{c.label}}
      </option>
    </select>
  </div>
</div>
