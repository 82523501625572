import {ErrorHandler, Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { throwError } from 'rxjs';
@Injectable()
export class SimpleErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    throwError(error);
  }
}

