<ng-content></ng-content>

<div *ngIf="filtertype === 'createdDate'">
  <input [owlDateTime]="cd"
         id="createdDate"
         type="text"
         [owlDateTimeTrigger]="cd"
         [(ngModel)]="filter.createdDate"
         [value]="filter.createdDate"
         class="form-control date-readonly"
         readonly
         [placeholder]="'DD-MM-YYYY'"
         [disabled]="filterDisabled === filtertype"
         #createdDate>
  <owl-date-time #cd
                 (afterPickerClosed)="dateChanged(createdDate, filtertype)"
                 [pickerType]="'calendar'"
                 [startAt]="today"></owl-date-time>
</div>

<div *ngIf="filtertype === 'status'">
  <select class="custom-select"
          id="status"
          [(ngModel)]="filter.status"
          (change)="search()"
          [disabled]="filterDisabled === filtertype"
          #status>
    <option value="" selected disabled>Statut</option>
    <option *ngFor="let status of statusList()"
            value="{{status}}">{{status | translate}}
    </option>
  </select>
</div>

<div *ngIf="filtertype === 'name'">
  <input class="form-control nomargin"
         id="name"
         type="search"
         [placeholder]="'Nom'"
         [(ngModel)]="filter.name"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #name>
</div>
<div *ngIf="filtertype === 'description'">
  <input class="form-control nomargin"
         id="description"
         type="search"
         [placeholder]="'Description'"
         [(ngModel)]="filter.description"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #description>
</div>
<div *ngIf="filtertype === 'title'">
  <input class="form-control nomargin"
         id="title"
         type="search"
         [placeholder]="'Titre du modèle'"
         [(ngModel)]="filter.title"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #title>
</div>
<div *ngIf="filtertype === 'firstName'">
  <input class="form-control nomargin"
         id="firstName"
         type="search"
         [placeholder]="'Prénom'"
         [(ngModel)]="filter.firstName"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #firstName>
</div>
<div *ngIf="filtertype === 'lastName'">
  <input class="form-control nomargin"
         id="lastName"
         type="search"
         [placeholder]="'Nom'"
         [(ngModel)]="filter.lastName"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #lastName>
</div>
<div *ngIf="filtertype === 'username'">
  <input class="form-control nomargin"
         id="username"
         type="search"
         [placeholder]="'Username'"
         [(ngModel)]="filter.username"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #username>
</div>
<div *ngIf="filtertype === 'email'">
  <input class="form-control nomargin"
         id="email"
         type="search"
         [placeholder]="'E-mail'"
         [(ngModel)]="filter.email"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #email>
</div>

<div *ngIf="filtertype === 'profile'">
  <select class="custom-select"
          id="profile"
          [(ngModel)]="filter.profile"
          (change)="search()"
          [disabled]="filterDisabled === filtertype"
          #profile>
    <option value="" selected disabled>Profil</option>
    <option *ngFor="let profile of listProfils"
            value="{{profile.id}}">{{profile.title}}
    </option>
  </select>
</div>

<div *ngIf="filtertype === 'enabled'">
  <select class="custom-select"
          id="enabled"
          [(ngModel)]="filter.enabled"
          (change)="search()"
          [disabled]="filterDisabled === filtertype"
          #enabled>
    <option value="true">{{'active' | translate}}</option>
    <option value="false">{{'desactive' | translate}}</option>
  </select>
</div>

<div *ngIf="filtertype === 'code'">
  <input class="form-control nomargin"
         id="code"
         type="search"
         [placeholder]="'Code'"
         [(ngModel)]="filter.code"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #code>
</div>
<div *ngIf="filtertype === 'label'">
  <input class="form-control nomargin"
         id="label"
         type="search"
         [placeholder]="'Libellé'"
         [(ngModel)]="filter.label"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'type_titrisation'">
  <input class="form-control nomargin"
         id="type_titrisation"
         type="search"
         [placeholder]="'Type titrisation'"
         [(ngModel)]="filter.type_titrisation"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'code_type_tit'">
  <input class="form-control nomargin"
         id="code_type_tit"
         type="search"
         [placeholder]="'Code type titrisation'"
         [(ngModel)]="filter.code_type_tit"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'code_cedant'">
  <input class="form-control nomargin"
         id="code_cedant"
         type="search"
         [placeholder]="'Code cedant'"
         [(ngModel)]="filter.code_cedant"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'raisonSociale'">
  <input class="form-control nomargin"
         id="raisonSociale"
         type="search"
         [placeholder]="'Raison sociale'"
         [(ngModel)]="filter.raisonSociale"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'familleTitrisation'">
  <input class="form-control nomargin"
         id="familleTitrisation"
         type="search"
         [placeholder]="'Famille titrisation'"
         [(ngModel)]="filter.familleTitrisation"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'typeActif'">
  <input class="form-control nomargin"
         id="typeActif"
         type="search"
         [placeholder]="'type actif'"
         [(ngModel)]="filter.typeActif"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'natureCession'">
  <input class="form-control nomargin"
         id="natureCession"
         type="search"
         [placeholder]="'Nature cession'"
         [(ngModel)]="filter.natureCession"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'c1'">
  <input class="form-control nomargin"
         id="c1"
         type="search"
         [placeholder]="'C1'"
         [(ngModel)]="filter.c1"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c2'">
  <input class="form-control nomargin"
         id="c2"
         type="search"
         [placeholder]="'C2'"
         [(ngModel)]="filter.c2"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c3'">
  <input class="form-control nomargin"
         id="c3"
         type="search"
         [placeholder]="'C3'"
         [(ngModel)]="filter.c3"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c4'">
  <input class="form-control nomargin"
         id="c4"
         type="search"
         [placeholder]="'C4'"
         [(ngModel)]="filter.c4"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c5'">
  <input class="form-control nomargin"
         id="c5"
         type="search"
         [placeholder]="'C5'"
         [(ngModel)]="filter.c5"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c6'">
  <input class="form-control nomargin"
         id="c6"
         type="search"
         [placeholder]="'C6'"
         [(ngModel)]="filter.c6"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c7'">
  <input class="form-control nomargin"
         id="c7"
         type="search"
         [placeholder]="'C7'"
         [(ngModel)]="filter.c7"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c8'">
  <input class="form-control nomargin"
         id="c8"
         type="search"
         [placeholder]="'C8'"
         [(ngModel)]="filter.c8"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c9'">
  <input class="form-control nomargin"
         id="c9"
         type="search"
         [placeholder]="'C9'"
         [(ngModel)]="filter.c9"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div><div *ngIf="filtertype === 'c10'">
  <input class="form-control nomargin"
         id="c10"
         type="search"
         [placeholder]="'C10'"
         [(ngModel)]="filter.c10"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'id'">
  <input class="form-control nomargin"
         id="id"
         type="search"
         [placeholder]="'Id'"
         [(ngModel)]="filter.id"
         [disabled]="filterDisabled === filtertype"
         aria-label="Search" (blur)="search()"
         #label>
</div>
<div *ngIf="filtertype === 'extensible'">
  <select class="custom-select"
          id="extensible"
          [(ngModel)]="filter.extensible"
          (change)="search()"
          [disabled]="filterDisabled === filtertype"
          #extensible>
    <option value="true">{{'Oui' | translate}}</option>
    <option value="false">{{'Non' | translate}}</option>
  </select>
</div>
